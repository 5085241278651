<template>
  <content-with-sidebar class="blog-wrapper" v-loading="loading" >
    <!-- blogs -->

    <b-card class="mb-0">
      <b-button to="/advice"  variant="primary"> <feather-icon icon="TwitchIcon" /> Yangi maslahat qo'shish</b-button>
    </b-card>
    <b-card-body>
      <b-row class="blog-list-wrapper" >
      <b-col
        v-for="blog in blogList"
        :key="blog.hashId"
        md="4"
      >
        <b-card
          tag="article"
          no-body
          class="custom-card"
        >
          <b-link :to="{ name: 'advice-blog-detail', params: { id: blog.id } }">
            <b-img
              :src="`https://bolajon.mtsh.uz/api/file-storages/preview/${blog.hashId}/avatar.jpg`"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'advice-blog-detail', params: { id: blog.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ blog.titleUz }}
              </b-link>
            </b-card-title>

            <div class="my-1 py-25">
              <b-link

              >
                <b-badge
                  pill
                  class="mr-75"
                  :variant="tagsColor(blog.type)"
                >
                  <span v-if="blog.type ==='UYNASH'">O‘ynash va o‘rganish</span>
                  <span v-if="blog.type ==='XAVF'">Xavfsizlik va himoya</span>
                  <span v-if="blog.type ==='OTAONA'">Ota-onalar burchagi</span>
                  <span v-if="blog.type ==='SALOMATLIK'">Salomatlik va farovonlik</span>
                  <span v-if="blog.type ==='SEZGIR'">Sezgir tarbiya va g‘amxo‘rlik</span>
                  <span v-if="blog.type ==='OVQAT'">Ovqatlantirish va ko‘krak suti bilan emizish</span>
                </b-badge>
              </b-link>
            </div>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <b-link
                :to="{ name: 'advice-blog-detail', params: { id: blog.id } }"
                class="font-weight-bold"
              >
                Batafsil
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
        </b-row>
    </b-card-body>
    <b-row class="blog-list-wrapper" >

      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              align="center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
          />
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Qidirish"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->

      <!--/ recent posts -->

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Kategoriyalar
        </h6>
        <div v-for="category in adviceCategoryTypes" :key="category.value" class="d-flex justify-content-start align-items-center mb-75">
          <b-link @click="selectCategory(category)">
            <b-avatar rounded size="32" :variant="tagsColor(category.value)" class="mr-75">
              <feather-icon :icon="category.icon" size="16" />
            </b-avatar>
          </b-link>
          <b-link @click="selectCategory(category)">
            <div class="blog-category-title text-body">
              {{ category.text }}
            </div>
          </b-link>
        </div>


      </div>
      <!--/ categories -->
    </div>

    <!--/ sidebar -->
  </content-with-sidebar>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BButton
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import spinner from '@/views/components/spinner/Spinner.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    spinner,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      loading: false,
      search_query: '',
      blogList: [],
      category: {
        type: '',
      },
      adviceCategoryTypes: [
        { value: 'UYNASH', text: 'O‘ynash va o‘rganish',icon:'RssIcon' },
        { value: 'XAVF', text: 'Xavfsizlik va himoya' ,icon:'ShieldIcon' },
        { value: 'OTAONA', text: 'Ota-onalar burchagi' ,icon:'UserCheckIcon' },
        { value: 'SALOMATLIK', text: 'Salomatlik va farovonlik',icon:'HeartIcon'  },
        { value: 'SEZGIR', text: 'Sezgir tarbiya va g‘amxo‘rlik' ,icon:'FeatherIcon' },
        { value: 'OVQAT', text: 'Ovqatlantirish va ko‘krak suti bilan emizish',icon:'ListIcon'},
        { value: null, text: 'Barchasi',icon:'RefreshCcwIcon'},
      ],
      blogSidebar: {},
      selectedCategory: null,

      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      adviceBlog: {
        "ageCategoryId": null,
        "type": null,
      },
    }
  },
  watch:{
    currentPage() {
      this.getcategory();
    },
  },
  created() {
    this.getcategory();
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category.value;
      this.adviceBlog.type = this.selectedCategory;
      this.getcategory();
    },
    getcategory() {
      this.loading = true;
      const currentPage = this.currentPage -1;
      this.$http
          .post(`/advice/find-all?page=${currentPage}&size=${this.perPage}`, this.adviceBlog)
          .then(response => {
            this.totalRows = response.data.totalElements;
            this.blogList = response.data.content;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          })
          .catch(error => {
            console.error('Axios error:', error)
          })
    },
    kFormatter,
    tagsColor(tag) {
      switch (tag) {
        case 'UYNASH':
          return 'light-primary';
        case 'SALOMATLIK':
          return 'light-danger';
        case 'XAVF':
          return 'light-secondary';
        case 'OTAONA':
          return 'light-success';
        case 'SEZGIR':
          return 'light-success';
        case 'OVQAT':
          return 'light-secondary';
        default:
          return 'light-primary';
      }
    },


  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-blog.scss';
.custom-card {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
img.card-img-top {
  max-height: 285px;
  min-height: 285px;
}
article.card.custom-card {
  max-height: 450px;
  min-height: 450px;
}

</style>
